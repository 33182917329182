import React from 'react';
import Header from "./Header";

function Index({location}) {
    return (
        <div>
         <Header />
        </div>
    )
}

export default Index

import React from "react";
import Index from "./../views/Login/Index";
import Layout from "./../components/Layout";

function Login({location}) {
  return (
    <div>
 <Layout location={location}>
        <Index  location={location}/>
      </Layout>
    </div>
  );
}

export default Login;

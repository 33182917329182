import React from "react";
import {
  FaCloudDownloadAlt,
  FaHeadphones,
  FaPhone,
  FaArrowDown,
} from "react-icons/fa";

function header() {
  return (
    <div>
      {" "}
      <div className="header-gradient ">
        <div className="max-w-7xl m-auto py-12 px-8">
          <div className="grid md:grid-cols-2 grid-cols-1">
           
  
          </div>
        </div>
      </div>
      <div className="language-cards px-10  pb-7 ">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 -mt-16 gap-10">
          {LanguageCards.map((LanguageCard) => {
            return (
              <div className="md:px-4 sm:px-20 px-2 py-5 rounded-2xl bg-white shadow">
                <div className="flex  flex-col justify-between w-full">
                  <div className="w-full flex ">
                    {" "}
                    <i>{LanguageCard.img}</i>
                  </div>
                  <div className="pt-4 flex items-baseline justify-between">
                    <h1 className="boxes-links text-2xl font-bold text-black-light mb-2 mt-10">
                      {LanguageCard.title}
                    </h1>
                    <i>{LanguageCard.downarrow}</i>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default header;
const LanguageCards = [
  {
    id: 1,
    title: "Communications APIs",
    img: <FaCloudDownloadAlt />,
    downarrow: <FaArrowDown />,
  },
  {
    id: 2,
    title: "Unified communication",
    img: <FaHeadphones />,
    downarrow: <FaArrowDown />,
  },
  {
    id: 3,
    title: "Contact Center",
    img: <FaPhone />,
    downarrow: <FaArrowDown />,
  },
];
